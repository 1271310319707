import React from 'react';

import { Container, Row, Col } from 'reusecore/Layout';
import Box from '../../reusecore/Box';
import Heading from '../../reusecore/Heading';
import Text from '../../reusecore/Text';
import { SectionTitle } from '../../reusecore/SectionTitle';

import SectionWrapper from './styles';
import data from './data';

const BestFeatures = () => {
  return (
    <SectionWrapper id="best-features">
      <Container>
        <Row>
          <Col className="sm-12 md-10 offset-md-1">
            <SectionTitle className="text-center ">
              <Heading className="section-title" as="h2">
                What makes VuFi different?
              </Heading>
            </SectionTitle>
          </Col>
        </Row>
        <Row>
          <Col className="md-12">
            <Box className="feature-item-wrapper">
              <Row>
                {data.map((item, index) => (
                  <Col className="lg-4 sm-6 xs-12" key={index}>
                    <Box className="feature-item text-left">
                      <img src={item.icon} alt={`${item.title}-icon`} />
                      <Heading as="h3">{item.title}</Heading>
                      <Text>{item.body}</Text>
                    </Box>
                  </Col>
                ))}
              </Row>
            </Box>
          </Col>
        </Row>
      </Container>
    </SectionWrapper>
  );
};

export default BestFeatures;
