import React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from 'assets/theme/theme';
import GlobalStyle from 'assets/theme';
import BestFeatures from 'sections/BestFeatures';
import Banner from 'sections/Banner';
import Layout from '../components/layout';
import Timeline from '../components/Timeline';
// markup
const IndexPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Layout title="Home">
        <Banner />
        <BestFeatures />
        <Timeline />
      </Layout>
    </ThemeProvider>
  );
};

export default IndexPage;
