import styled from 'styled-components';

const BannerWrapper = styled.div`
  position: relative;
  overflow: hidden;
  min-height: 670px;
  background: #f7f7f7;
  box-sizing: border-box;
  padding: 40px 0px 145px;

  .banner-image {
    position: relative;
    .banner__thumb {
      position: absolute;
      width: 150%;
      max-width: 150%;
    }
  }

  .section__particle {
    position: absolute;
    &.top-left {
      left: -98px;
      top: -60px;
      height: 150px;
    }
    &.top-right {
      top: 65px;
      right: 28px;
      height: 74px;
    }
    &.bottom-left {
      bottom: 0px;
      left: 15px;
      height: 150px;
    }
    &.two {
      top: 6px;
      left: 140px;
      height: 87px;
    }
    &.bottom-right {
      right: -40px;
      bottom: 0px;
      height: 200px;
    }
  }

  .banner-content {
    padding-top: 48px;

    .offer-text {
      color: #ffffff;
      padding: 12px 30px;
      border-radius: 30px;
      font-size: 14px;
      margin-bottom: 33px;
      font-weight: 400;
      background: rgb(35, 42, 213);
      background: linear-gradient(87deg, rgba(35, 42, 213, 1) 45%, rgba(194, 56, 231, 1) 100%);
    }

    h1 {
      margin: 0 0 30px 0;
      font-size: 45px;
      line-height: 65px;
    }

    p {
      margin-bottom: 60px;
      color: #181818;
      font-size: 18px;
      line-height: 28px;
    }

    .banner-btn {
      a {
        font-size: 16px;
        border: 1px solid #ffffff;
        transition: all 0.3s ease-in;
      }

      a + a {
        margin-left: 20px;
      }
    }
  }

  @media only screen and (max-width: 912px) {
    .banner__thumb {
      display: none;
    }
    .banner-content {
      h1 {
        font-size: 40px;
        line-height: 60px;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .banner-content {
      h1 {
        font-size: 35px;
        line-height: 55px;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    .banner-content {
      h1 {
        font-size: 30px;
        line-height: 50px;
        br {
          display: none;
        }
      }
      .text {
        br {
          display: none;
        }
      }
    }
    .section__particle {
      &.bottom-right {
        display: none;
      }
    }
  }
  @media only screen and (max-width: 375px) {
    .banner-content {
      h1 {
        font-size: 25px;
        line-height: 40px;
      }
      .banner-btn {
        .btn {
          /* width: 155px;
          padding: 18px 5px; */
        }
        a + a {
          margin: 0px;
          margin-top: 4px;
        }
      }
    }
  }
  @media only screen and (max-width: 320px) {
    .banner-content {
      h1 {
        font-size: 22px;
        line-height: 35px;
      }
      .banner-btn {
        /* a + a {
          margin: 15px 0 0 0;
        } */
      }
    }
  }
`;
export default BannerWrapper;
