import styled from 'styled-components';

export const SectionTitleWrapper = styled.div`
  .heading {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    margin: 0;
  }

  .text {
    margin-top: 27px;
  }
  .section-desc {
    padding: 0px 128px;

    @media only screen and (max-width: 1440px) {
      padding: 0px 100px;
    }

    @media only screen and (max-width: 768px) {
      padding: 0px 32px;
    }

    @media only screen and (max-width: 480px) {
      padding: 0px;
    }
  }
`;

export const SectionBgWrapper = styled.div`
  .heading {
    padding: 10px 0 10px 22px;
  }
`;
