import styled from 'styled-components';

const FeaturesSectionWrapper = styled.div`
  padding: 85px 0 0;
  .section-title {
    color: #ed2939;
  }

  .feature-item-wrapper {
    padding: 25px 0px;
    border-radius: 10px;

    .feature-item {
      max-width: 275px;
      padding: 15px 0px;
      border-radius: 8px;
      transition: all 0.3s ease-in;
      img {
        height: 60px;
        margin-bottom: 20px;
      }

      h3 {
        font-size: 24px;
        font-weight: 600;
        margin: 0 0 12px 0;
      }
      p {
        font-weight: normal;
        line-height: 20px;
        font-size: 14px;
        /* margin: 0; */
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .feature {
      margin-bottom: 30px;
    }
    .feature-item-wrapper {
      padding: 45px 32px 25px 32px;
    }
  }
  @media only screen and (max-width: 480px) {
    padding: 55px 0 0px 0;
  }
`;

export default FeaturesSectionWrapper;
