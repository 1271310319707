import React from 'react';
import {
  TimelineWrapper,
  TimelineContainer,
  OrderedList,
  ListItem,
  TimelineBox,
  Handler,
  TimelineHeading,
  Text,
  Span,
} from './style';

import { data } from './data';

const Timeline = () => (
  <>
    <TimelineWrapper>
      <TimelineContainer id="scrollMe">
        <OrderedList>
          {data.map(
            ({ topHeading, topText, bottomHeading, bottomText, isOdd }, i) => {
              const directionElements = isOdd ? (
                <>
                  <TimelineBox top>
                    <TimelineHeading>{topHeading}</TimelineHeading>
                    <Text>{topText}</Text>
                  </TimelineBox>
                  <Handler>
                    <TimelineHeading>{bottomHeading}</TimelineHeading>
                    <Text>{bottomText}</Text>
                  </Handler>
                  <Span top>&nbsp;</Span>
                </>
              ) : (
                <>
                  <Handler top>
                    <TimelineHeading>{topHeading}</TimelineHeading>
                    <Text>{topText}</Text>
                  </Handler>
                  <TimelineBox>
                    <TimelineHeading>{bottomHeading}</TimelineHeading>
                    <Text>{bottomText}</Text>
                  </TimelineBox>
                  <Span bottom>&nbsp;</Span>
                </>
              );
              return <ListItem key={i}>{directionElements}</ListItem>;
            }
          )}
        </OrderedList>
      </TimelineContainer>
    </TimelineWrapper>
  </>
);

export default Timeline;
