import noPreMining from '../../assets/svg/no-pre-mining.svg';
import elasticity from '../../assets/svg/elasticity.svg';
import collateralized from '../../assets/svg/collateralized.svg';
import stableCoin from '../../assets/svg/stable-coin.svg';
import coupon from '../../assets/svg/coupon.svg';
import gasFee from '../../assets/svg/gass-fee.svg';

const data = [
  {
    icon: noPreMining,
    title: 'No Pre-mining',
    body:
      'We do not pre-mine our coin which means there is a fair launch. In other words, there are no perks for any group - be it the founders, contributors, employees, or initial token supporters.',
  },
  {
    icon: elasticity,
    title: 'Elasticity',
    body:
      'Each cycle on the VuFi protocol lasts for approximately an hour, after which the supply expands, contracts, or stays the same depending on the value of the VuFi coin. This shorter cycle increases the frequency of expansions or contractions, thus providing more minting and burning opportunities for traders.',
  },
  {
    icon: collateralized,
    title: 'Collateralized',
    body:
      'VuFi is a collateralized coin, of which the collateral would initially be USDC. The ratio of the collateral goes up or down based on the price movement. If the VuFi trades at $1.2 then the ratio would be 1.2:1. Likewise, when the VuFi trades for $0.80 then the ratio would be 0.8:1. However, this may change depending on community votes in this regard. ',
  },
  {
    icon: stableCoin,
    title: 'Stable Coin',
    body:
      'VuFi is a decentralized stable coin, which means the holder is in absolute control of it. Unlike centralized stable coins like the USDC and the USDT, there is no particular person or group of persons who control the way things run. In the case of centralized stable coins, those controlling it can prevent certain addresses from using their coins.',
  },
  {
    icon: coupon,
    title: 'Coupon Mechanism',
    body:
      'The ratio of the coupon changes at the end of every cycle, and that is when the expansion or contraction of the money supply happens. Once the token holder chooses to bond, everything takes place within the wallet. So, there is no need for the bonded token holder to take any action as the minting or burning happens within the wallet.',
  },
  {
    icon: gasFee,
    title: 'Gas Fee Saving',
    body:
      'Designed to reduce gas fee usage through a unique stabilizing component, stake once and participate forever without needing to call transactions. ',
  },
];

export default data;
