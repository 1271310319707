import React from 'react';
import { Link } from 'gatsby';
import { Container, Row, Col } from '../../reusecore/Layout';
import Box from '../../reusecore/Box';
import Heading from '../../reusecore/Heading';
import Text from '../../reusecore/Text';
import bannerImage from '../../assets/images/vufi-backdrop.png';
import BannerWrapper from './styles';

const Banner = () => {
  return (
    <BannerWrapper>
      <Container>
        <Row>
          <Col className='lg-6 xs-12'>
            <Box className='banner-content'>
              <Heading>What is VuFi?</Heading>
              <Text>
                VuFi is an algorithmic elastic supply stable coin that aims to
                introduce more stability in cryptocurrency markets for the
                average user. This innovative collateral-backed stable coin uses
                the dollar inflation rate as the target price, which enables
                traders to hedge their overall portfolio against the wild swings
                in the global economy.
              </Text>
              <Box className='banner-btn'>
                <Link
                  to='https://app.vufi.finance/'
                  className='btn'
                  target='_blank'
                >
                  Launch Dashboard &#x2192;
                </Link>
              </Box>
            </Box>
          </Col>
          <Col className='lg-6 xs-12'>
            <Box className='banner-image'>
              <img
                src={bannerImage}
                className='banner__thumb'
                alt='cryptik banner thumb'
              />
            </Box>
          </Col>
        </Row>
      </Container>
    </BannerWrapper>
  );
};

export default Banner;
