import styled from 'styled-components';

export const TimelineWrapper = styled.section`
  position: relative;
  display: block;
`;

export const TimelineContainer = styled.div`
  overflow: hidden auto;
  padding: 0px 2rem 50px;
  cursor: grab;
  white-space: nowrap;
  position: relative;
`;

export const OrderedList = styled.ol`
  margin-top: 350px;
  padding: 0;
  list-style: none;
  margin-bottom: auto;
  min-height: 350px;
  width: auto;
  position: relative;

  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const ListItem = styled.li`
  display: inline-block;
  width: 25%;
  height: 2px;
  padding-top: 50px;
  position: relative;
  border-top: 2px solid #e42b39;
  margin-left: -5px;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    border: 0;
    padding: 0;
    display: block;
  }
`;

export const TimelineBox = styled.div`
  position: absolute;
  top: ${(props) => (props.top ? '-260px' : '105px')};
  background-color: #e42b39;
  border-radius: 5px;
  color: #fff;
  padding: 0 0 20px;
  margin: 0px 10px 100px;
  white-space: normal;
  width: 300px;
  min-height: 135px;

  @media (max-width: 768px) {
    background-color: transparent;
    color: inherit;
    position: relative;
    width: 100%;
    margin: 0 0 20px;
    padding: 0;
    top: 0;
    min-height: auto;
  }
`;

export const Handler = styled.div`
  position: relative;
  top: ${(props) => (props.top ? '-200px' : '-25px')};
  white-space: normal;
  width: 300px;
  min-height: 135px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0 0 20px;
    top: 0;
    min-height: auto;
  }
`;

export const TimelineHeading = styled.h3`
  font-size: 24px;
  font-weight: 600;
  margin: 20px 25px 12px;
  text-align: center;
  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

export const Text = styled.p`
  text-align: center;
  width: 65%;
  margin: 0 auto;
  line-height: 1.2;
`;

export const Span = styled.span`
  width: 20px;
  height: 20px;
  margin-left: -11px;
  background: #fff;
  border: 5px solid #e42b39;
  border-radius: 50%;
  box-shadow: 0 0 0 0px #fff;
  text-align: center;
  line-height: 50px -10;
  color: #df8625;
  font-size: 2em;
  font-style: normal;
  position: absolute;
  top: -12px;
  left: 50%;

  @media (max-width: 768px) {
    display: none;
  }

  &:after {
    content: '';
    color: #e42b39;
    width: 8px;
    height: 8px;
    background: #e42b39;
    position: absolute;
    left: 10%;
    border-radius: 100%;

    ${(props) =>
      props.top &&
      `
      bottom: 90px;
    `}

    ${(props) =>
      props.bottom &&
      `
      top: 90px;
    `}
  }

  &:before {
    content: '';
    color: #e42b39;
    width: 2px;
    background: #e42b39;
    position: absolute;
    left: 40%;
    ${(props) =>
      props.top &&
      `
        top: -85px;
        height: 82px;
      `}
    ${(props) =>
      props.bottom &&
      `
        height: 85px;
        bottom: -85px;
      `}
  }
`;
