export const data = [
  {
    topHeading: 'November 2020',
    topText: 'Founded by two software engineers in London',
    bottomHeading: 'March 2021',
    bottomText: 'Launched official website and whitepaper',
    isOdd: true,
  },
  {
    topHeading: 'May 2021',
    topText: 'Finished tokenomics calculation',
    bottomHeading: 'September 2021',
    bottomText: 'Complete basic functions',
    isOdd: false,
  },
  {
    topHeading: 'November 2021',
    topText: 'Airdrop launch giveaway',
    bottomHeading: 'December 2021',
    bottomText: 'Public launch & Multi chain ecosystem support ',
    isOdd: true,
  },
  {
    topHeading: 'Q1 2022',
    topText: 'Lending protocol for non-fungible token (NFT) ',
    bottomHeading: 'Q4 2022',
    bottomText: 'Own network protocol with proof of stake',
    isOdd: false,
  },
];
